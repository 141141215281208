

<template>
  <div id="profile-dropdown" :class="{smallerZIndex: !dropdownOpen}">
    <div :class="{scaleInDropdown: dropdownOpen, dropdownShadow: dropdownOpen}" id="dropdown">
      <div @click="toggleDropdown" id="round-image">
        <div id="border" :class="{ 'profile-shadow': !dropdownOpen }">
          <font-awesome-icon
            id="user-icon"
            icon="user"
            :class=" showDarkBrand ? 'dark-color' : 'green-color'"
          ></font-awesome-icon>
        </div>
      </div>
      <div
        @click="goToProfile"
        :class="{ dropdownItem: true, dropdownItemBorderBottom: dropdownOpen, hideHover: !dropdownOpen }"
      >
        <h3>{{ dropdownOpen? labels.profile.dropdown.myProfile[language] : ""}}</h3>
      </div>
      <div
        @click="goToHistory"
        :class="{ dropdownItem: true, dropdownItemBorderBottom: dropdownOpen, hideHover: !dropdownOpen }"
      >
        <h3>{{ labels.profile.dropdown.myOrders[language] }}</h3>
      </div>
      <div
        :class="{ dropdownItem: true, dropdownBottomBorder: true, dropdownItemBorderBottom: dropdownOpen, hideHover: !dropdownOpen }"
      >
        <div @click="logOut" id="exit-dropdown">
          <h3>{{ labels.profile.dropdown.signOut[language] }}</h3>
          <font-awesome-icon id="exit-icon" icon="door-open"></font-awesome-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserApi from "../api/user";

export default {
  name: "ProfileDropdown",
  components: {},
  computed: {
    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    userId() {
      return this.$store.getters.getUserId;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    showDarkBrand() {
      this.$route.name;
      if (this.$route.name) {
        let routeName = this.$route.name;
        let darkProfileScreen = routeName.includes("profile") && this.$route.query && this.$route.query.brand == 'dark'
        let showDarkBrand = routeName.includes("services") || routeName.includes("menu") || routeName.includes("open-tables") || routeName.includes("open-rooms") || routeName.includes("store-order-history") || routeName.includes("cart") || routeName.includes("history") || routeName.includes("pin") || darkProfileScreen;
        return showDarkBrand;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  props: ["isApplication"],
  methods: {
    toggleDropdown: function () {
      this.dropdownOpen = !this.dropdownOpen;
      this.$emit("toggle-profile-dropdown");
    },

    goToProfile: function () {
      if (this.dropdownOpen) {
        if (this.$route.name) {
          let routeName = this.$route.name;
          if (routeName.includes("services") || routeName.includes("menu") || routeName.includes("cart") || routeName.includes("history")) {
            let queryObject = { brand: 'dark' };
            if (this.isApplication) {
              queryObject.isApplication = true;
            }
            this.$router.push({ name: "profile", params: { userId: this.userId }, query: queryObject });
          } else {
            this.$router.push({ name: "profile", params: { userId: this.userId } });
          }
        } else {
          this.$router.push({ name: "profile", params: { userId: this.userId } });
        }
        this.isLoading = true;
      }
    },

    goToHistory: function () {
      let queryObject = {};
      if (this.isApplication) {
        queryObject.isApplication = true;
      }
      this.$router.push({ name: "history", params: { userId: this.userId }, query: queryObject });

      this.isLoading = true;
    },

    logOut: function () {
      UserApi.logout(this.authToken, this.jwt);
      this.$store.dispatch("setUserData", {});

      let routerName = this.$router.currentRoute.name;
      if (routerName.includes("profile") || routerName.includes("history") || (routerName.includes("reviews") && !routerName.includes("store"))) {
        this.$router.push({ name: "landing" })
      }
    },
  },
};
</script>

<style scoped>
#profile-dropdown {
  z-index: 201;
}

#profile {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#round-image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 45px;
  color: #198b4a;
  border-radius: 100%;
  margin-right: 10px;
  margin-top: 2px;
}

.green-color {
  color: #198b4a;
}

.dark-color {
  color: #003340;
}

@media screen and (max-width: 800px) {
  #round-image {
    height: 40px;
  }
}

.profile-shadow {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#border {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1.2px solid white;
  width: 45px;
  height: 45px;
  background: white;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  #border {
    width: 40px;
    height: 40px;
  }
}

#user-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}
@media screen and (max-width: 800px) {
  #user-icon {
    width: 18px;
    height: 18px;
  }
}

#down {
  color: #198b4a;
  margin-left: 6px;
  margin-bottom: 5px;
}

.scaleInDropdown {
  animation: scaleInDropdown 0.3s forwards ease-in-out;
}

@keyframes scaleInDropdown {
  from {
    clip-path: circle(46% at 100% 0%);
    background: transparent;
    box-shadow: 0 0 0 black;
  }

  to {
    clip-path: circle(150% at 100% 100%);
    background: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.5), 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.smallerZIndex {
  z-index: 150 !important;
}

#dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 140px; /* otherwise, part of the round background gets cut off */
  min-height: 50px;
  margin-bottom: 5px;
  background: transparent;
  border-radius: 16px;
  clip-path: circle(46% at 100% 0%);
  z-index: 201;
}

.dropdownShadow {
  box-shadow: 2px 2px 4px black;
}

#area-list {
  margin-top: 1vh;
  width: 25vw;
  max-height: 25vh;
  background: white;
  border-radius: 7px;
  z-index: 201;
  overflow-y: scroll;
}

#area-list::-webkit-scrollbar {
  width: 10px;
}

#area-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(24, 49, 22, 0.3);
}

#area-list::-webkit-scrollbar-thumb {
  background-color: #198b4a;
  border-radius: 100px;
  border: 1.9px solid white;
}

.dropdownItem {
  padding: 6px 11px 0 11px;
  cursor: pointer;
}

.dropdownItemBorderBottom {
  border-bottom: 1px solid rgba(56, 117, 77, 0.4);
}

.dropdownItem:hover {
  background: #efefef;
}

.hideHover:hover {
  background: transparent;
  cursor: default;
}

.dropdownItem > h3,
#exit-dropdown > h3 {
  font-size: 14px;
  color: gray;
}
@media screen and (max-width: 800px) {
  .dropdownItem > h3,
  #exit-dropdown > h3 {
    font-size: 13px;
  }
}
@media (max-width: 300px) {
  .dropdownItem > h3,
  #exit-dropdown > h3 {
    font-size: 10px;
  }
}

#exit-icon {
  color: rgba(255, 51, 0, 0.7);
}

#globe {
  margin-right: 1.5px;
  /* color: cadetblue; */
}

#exit-dropdown {
  display: flex;
  justify-content: space-between;
}

.dropdownTopBorder {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.dropdownBottomBorder {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
</style>